<template>

   <nav class="d-flex justify-content-center mb-5" aria-label="Paginação">
          <ul class="pagination">
            <li class="page-item" >
                  <a class="page-link" style="width:80px" href="#" v-show="showPreviousButton" @click="goPreviousPage()"> 
                    <i class='fas fa-chevron-left'></i>
                  </a>
            </li>
            <li class="page-item" v-for="p in this.totalPages" :key="p"  >               
                <a class="page-link" v-if="page < p"  @click="goPage(p)"> {{ p }} </a>
                <a class="page-link" v-if="page == p"> <span class="btn-primary btn-sm ">{{ p }}</span></a>
                <a class="page-link" v-if="page > p"  @click="goPage(p)"> {{ p }} </a>
            </li>

             <li class="page-item">
                <a class="page-link" style="width:80px" v-show="showNextButton" @click="goNextPage()">
                  <i class='fas fa-chevron-right'></i>
                </a>
            </li>
 
          </ul>

        </nav>
</template>

<script>
  export default{
    props: ["total","page","itensPerPage","totalPaginas"],
    computed: {
      totalPages: function(){        
        let totalPage =  Math.ceil(this.total/this.itensPerPage)||1
        let array_pages = []        

        for(var j = 1;j < 16;j++) 
        {          
          array_pages.push(j)
        }

        console.log(array_pages);
        
        if(totalPage  > 15 )
        {
          totalPage = 15
        }
        return totalPage
      },
      showNextButton: function(){
        
       return  this.page < this.totalPages
      },
      showPreviousButton: function(){
       return this.page!=1
      } 
    },        
    methods: {
      goNextPage: function(){
        this.$emit('change-page',this.page+1)
      },
      goPreviousPage: function(){
        this.$emit('change-page',this.page-1)
      },
      goFirstPage: function(){
        this.$emit('change-page',1)
      },
      goLastPage: function(){
        this.$emit('change-page',this.totalPages)
      },
      goPage: function(page){
        this.$emit('change-page',page)
      }
    }
  }
</script>